/* eslint-disable max-len */
import http from '@glu/core/src/http'; // in fact it takes `system/gluOverride/http.js`
// This is sessionStorage by default.
import gluStore from '@glu/store';
import { $, Model } from '@glu/core';
import userInfo from 'etc/userInfo';
import { navigateTo } from 'common/util/navigationUtils';

// TODO
// This is PCM store js file, not localStorage/sessionStorage.
// import store from 'pcm/common/store';
// It was/is good to use it, but for now it doesn't work due to time delay.
// TODO

export const constants = {
    pcmGetTokenEndpoint: '/banking-services/api/ptxConnect/getToken',
    pcmGetAdminTokenEndpoint: '/admin-services/api/ptxConnect/getAdminToken',
    pcmGetAdminTokenEndpointFromCLient: '/banking-services/api/ptxConnect/getAdminToken',
    accessTokenName: 'accessToken',
    accessTokenExpiresAt: 'expiresAt',
};

// Experimental way. This code created similarly to etc/branding.js
// In future we need to change it to React.lazy() + <Suspense>
const AppendPtxCss = Model.extend({
    stylesApplied: false,
    apply(callback, skipUserCheck) {
        if (!this.stylesApplied && (userInfo.isLoggedIn() || skipUserCheck)) {
            $('head').append(`<link rel="stylesheet" href="${window.Bottomline.contentHashes.ptxConnectCss}">`);
            this.stylesApplied = true;
        }
        (callback || $.noop)();
    },
});

export const appendPtxCss = new AppendPtxCss();

export function getAccessTokenPromise() {
    return new Promise(((resolve, reject) => {
        http.get(
            constants.pcmGetTokenEndpoint,
            (serverResponse) => {
                const accessToken =
                    serverResponse.item.find(elem => elem.name === constants.accessTokenName).value;
                const expiresAt =
                    serverResponse.item.find(elem =>
                        elem.name === constants.accessTokenExpiresAt).value;

                if (accessToken) {
                    gluStore.set('ptxAccessToken', accessToken);
                }
                if (expiresAt) {
                    gluStore.set('ptxExpiresAt', expiresAt);
                }

                resolve({
                    accessToken,
                    expiresAt,
                });
            },
            (err) => {
                reject(err);
            },
        );
    }));
}

export function isPrinterAccessAdmin() {
    const type = gluStore.get('ptxAccessPrintType');
    if (type === 'admin') {
        return true;
    } else if (type === 'client') {
        return false;
    }
    return false;
}

export function isPrinterAccessClient() {
    const type = gluStore.get('ptxAccessPrintType');
    if (type === 'client') {
        return true;
    } else if (type === 'admin') {
        return false;
    }
    return false;
}

export function setPrinterTypeAccess(type) {
    gluStore.set('ptxAccessPrintType', type);
    if (type === 'admin') {
        gluStore.unset('ptxPreviousAccessToken');
    }
}

/**
 * PCM-6290 - For some ptx integrated screen we need first eh clientToken to load the initial list
 * using proper credentials and for action operations we do need the admin token.
 * It can and shoud be fixed by ptx team one day, then this code should be removed.
 */

export function getTemporaryAdminAccessTokenPromise(userGroup, fromClient) {
    const data = {
        item: [
            {
                name: 'usergroup',
                value: fromClient ? '' : userGroup,
            },
        ],
    };

    const url =
        fromClient ?
            constants.pcmGetAdminTokenEndpointFromCLient : constants.pcmGetAdminTokenEndpoint;
    const previousToken = gluStore.get('ptxAccessToken');
    gluStore.set('ptxPreviousAccessToken', previousToken);
    return new Promise(((resolve, reject) => {
        http.post(
            url,
            data,
            (serverResponse) => {
                const accessToken =
                    serverResponse.item.find(elem => elem.name === constants.accessTokenName).value;
                const expiresAt =
                    serverResponse.item.find(elem =>
                        elem.name === constants.accessTokenExpiresAt).value;

                if (accessToken) {
                    gluStore.set('ptxAccessToken', accessToken);
                }
                if (expiresAt) {
                    gluStore.set('ptxExpiresAt', expiresAt);
                }

                resolve({
                    accessToken,
                    expiresAt,
                });
            },
            (err) => {
                reject(err);
            },
        );
    }));
}

export function getAdminAccessTokenPromise(userGroup, fromClient) {
    const data = {
        item: [
            {
                name: 'usergroup',
                value: fromClient ? '' : userGroup,
            },
        ],
    };

    const url =
        fromClient ?
            constants.pcmGetAdminTokenEndpointFromCLient : constants.pcmGetAdminTokenEndpoint;

    return new Promise(((resolve, reject) => {
        http.post(
            url,
            data,
            (serverResponse) => {
                const accessToken =
                    serverResponse.item.find(elem => elem.name === constants.accessTokenName).value;
                const expiresAt =
                    serverResponse.item.find(elem =>
                        elem.name === constants.accessTokenExpiresAt).value;

                if (accessToken) {
                    gluStore.set('ptxAccessToken', accessToken);
                }
                if (expiresAt) {
                    gluStore.set('ptxExpiresAt', expiresAt);
                }

                resolve({
                    accessToken,
                    expiresAt,
                });
            },
            (err) => {
                reject(err);
            },
        );
    }));
}

// TODO - code around expiration doesn't behave correct always. Time matters.
export function isAccessTokenExpired(utcExpiresAtString) {
    const clientNow = new Date(); // it will be date created using Client (browser) timezone
    const clientExpiryAt =
        new Date(utcExpiresAtString);

    return clientNow >= clientExpiryAt;
}

/**
 // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
 * When PCM does call PT-X Connect API endpoint, it's expected to have one Request token - "Authorization".
 */
export function setPtxHeaders(xhr, requestOptions) {
    // Note 1: http.setRequestHeaders() as alternative, but...
    // Note 2: Also could be useful to know/remember about "appBus.on('http:request')"
    // eslint-disable-next-line max-len
    // Note 3: Yes, from sessionStorage, because with using 'pcm/common/store' it doesn't work in time.

    let tokenValue = gluStore.get('ptxAccessToken');
    const previousTokenValue = gluStore.get('ptxPreviousAccessToken');
    const ptxExpiresAtString = gluStore.get('ptxExpiresAt');

    if (requestOptions.url === '/api/printers' && previousTokenValue) {
        tokenValue = previousTokenValue;
    }
    if (tokenValue && !isAccessTokenExpired(ptxExpiresAtString)) {
        xhr.setRequestHeader('Authorization', `Bearer ${tokenValue}`);
    } else {
        // else it will go as 401, and log out.
        // Yes, sessionStorage will be cleared after window/tab closed.
        // But for better security, let's clear sessionStorage explicitly,
        // so that next user can't access PTX pages with yet valid accessToken.
        gluStore.unset('ptxAccessToken');
        gluStore.unset('ptxExpiresAt');
        navigateTo('');
    }
}

export default {
    appendPtxCss,
    getAccessTokenPromise,
    isAccessTokenExpired,
    setPtxHeaders,
};
